<template>
  <div class="leftTree" v-loading="navloading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <div class="tree" v-if="leftNav.length == 0">
      <myScroll>
        <div class="tree-box">
          <el-tree
            ref="treeList"
            v-if="leftMenu"
            :data="leftMenu"
            node-key="id"
            icon-class=""
            :default-expanded-keys="expandedid"
            :current-node-key="currentid"
            @node-drag-start="handleDragStart"
            @node-drag-enter="handleDragEnter"
            @node-drag-leave="handleDragLeave"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-drop="handleDrop"
            @node-click="debounce"
            @node-contextmenu="nodecontextmenu"
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :props="defaultProps"
          >
            <span
              class="slottree"
              slot-scope="{ node, data }"
              :class="{ notfolder: data.nodeType !== 'Folder', currentid: data.id == currentid && data.nodeType === 'Page' }"
            >
              <i :class="data.nodeType === 'Folder' ? 'el-icon-caret-right' : ''"></i
              ><span v-if="data.isOutsideUrl" @click="tourl(data, data.url)">{{ node.label }}</span
              ><span v-if="!data.isOutsideUrl">{{ node.label }}</span>
            </span>
          </el-tree>
          <div class="editMenu" v-if="openeditMenu" :style="{ top: editMenuTop }">
            <ul v-if="isFile">
              <li v-if="$commonJs._point('BI报表_文件夹_编辑')" @click="changeName"><i class="iconfont iconpeizhibeifen"></i>编辑文件夹</li>
              <li v-if="$commonJs._point('BI报表_文件夹_删除')" @click="delFile(clickNode)"><i class="iconfont iconshanchu"></i>删除文件夹</li>
              <li v-if="$commonJs._point('DS_F0001')" @click="lockpage('file', clickNode)">
                <i class="iconfont iconsuoding"></i>{{ clickNode.isLocked ? '解锁文件夹' : '锁定文件夹' }}
              </li>
            </ul>
            <ul v-else>
              <li v-if="!isLinks && $commonJs._point('BI报表_仪盘表_添加卡片')" @click="addCard"><i class="el-icon-circle-plus"></i>添加卡片</li>
              <li v-if="$commonJs._point('BI报表_仪盘表_配置')" @click="changeName"><i class="iconfont iconpeizhibeifen"></i>编辑页面</li>
              <li v-if="$commonJs._point('BI报表_仪盘表_删除')" @click="delDashboard(clickNode)"><i class="iconfont iconshanchu"></i>删除页面</li>
              <li v-if="$commonJs._point('DS_F0002')" @click="lockpage('page', clickNode)">
                <i class="iconfont iconsuoding"></i>{{ clickNode.isLocked ? '解锁页面' : '锁定页面' }}
              </li>
            </ul>
          </div>
          <div v-if="$commonJs._point('BI报表_文件夹_新建') || $commonJs._point('BI报表_仪盘表_新建')" class="addbtn dis_flex_center">
            <el-dropdown szie="mini" placement="right" trigger="click" @command="openAdd">
              <span class="el-dropdown-link">+添加</span>
              <el-dropdown-menu class="thmemRightMenu" slot="dropdown">
                <el-dropdown-item v-if="$commonJs._point('BI报表_文件夹_新建')" icon="el-icon-circle-plus" command="file">添加文件夹</el-dropdown-item>
                <el-dropdown-item v-if="$commonJs._point('BI报表_仪盘表_新建')" icon="el-icon-circle-plus" command="dashboard">添加页面</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </myScroll>
    </div>
    <div v-if="leftNav.length > 0">
      <ul>
        <li v-for="(item, index) in leftNav" :key="index">{{ item.title }}</li>
      </ul>
    </div>

    <el-dialog
      v-if="addvisible"
      class="choiceCardDialog thmemBottomMenu"
      title="选择分析模型"
      :visible.sync="addvisible"
      width="700px"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="cancel"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    >
      <div class="dialog-body">
        <el-radio-group class="analysisRadio" v-model="analysisRadio">
          <div class="radio-item" v-for="list in analysisList" :key="list.category">
            <el-radio :label="list.category"
              >{{ list.name }} <i v-if="list.category === 'advance'" class="wenhao iconfont iconwenhao4 "><span>即原来的高级分析</span></i></el-radio
            >
            <span class="radio-tips">{{ list.content }}</span>
          </div>
        </el-radio-group>
        <div class="choiceBtn" @click="choiceCard">从已有卡片中选择 >></div>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="cancel">取 消</el-button>
        <el-button type="primary" size="small" @click="next">下一步</el-button>
      </div>
    </el-dialog>
    <add-card-dialog
      ref="addcardref"
      :key="addcardkey"
      v-if="clickNode && clickNode.nodeType !== 'Folder' && openCard"
      :openCard="openCard"
      :cardData="cardData"
      :clickNode="clickNode"
      @savecard="savecard"
    ></add-card-dialog>
    <add-file
      v-if="dialogfile"
      :isAdd="isAdd"
      :isFile="isFile"
      :dialogfile="dialogfile"
      :clickNode="clickNode"
      @fileDialogClose="fileDialogClose"
      @changeMenu="changeMenu"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { analysisData } from '@/datajs/static.js'
import addCardDialog from '@/components/leftNav/addCardDialog.vue'
import addFile from '@/components/leftNav/addFile.vue'
import { unlockDashboard, lockDashboard, lockFolder, unlockFolder } from '@/api/apiV2_dashboard'
import { getMenuLoad, deleteDashboard, RectifyOrder, deleteFolder, getBookmarkTab } from '@/api/apiV2_dashboard'

import BI_config from '@/utils/config'

// 先判断 新增 / 编辑  再判断 ==》 文件夹 / 页面

export default {
  props: ['dashboard', 'addDashboard'],
  components: { addCardDialog, addFile },
  data() {
    return {
      leftMenu: '',
      navloading: false,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      expandedid: [],
      currentid: '',

      openeditMenu: false,
      editMenuTop: '',
      currentFilder: null,

      addvisible: false,
      analysisRadio: 'behavior',
      analysisList: [],
      isAdd: false,
      isFile: false,
      isLinks: false,
      clickNode: '',

      addcardkey: 0,
      openCard: false,
      cardData: [],

      dialogfile: false,
      dashboardID: this.$route.hash.replace('#/', ''),

      debounce: t => this.$commonJs2.debounce(this.handleClick(t), 1000),
      isClick: false,
    }
  },
  computed: {
    ...mapState({
      leftNav: state => state.leftNav,
      projectID: state => state.projectID,
    }),
  },
  watch: {
    'dashboard.length': function() {
      this.init()
      if (this.dashboard.length > 0) {
        this.leftMenu = this.dashboard
        let expandeds = this.dashboard.filter(x => {
          if (this.currentid) {
            let filters = x.children.filter(c => {
              return c.id == this.currentid
            })
            return x.id === this.currentid || (filters && filters.length > 0)
          } else {
            return (x.children && x.children.length > 0) || x.nodeType === 'Page'
          }
        })
        if (expandeds.length > 0) {
          this.expandedid.push(expandeds[0].id)
        }
        this.$nextTick(function() {
          this.$refs.treeList.setCurrentKey(this.currentid)
        })
      }
    },
    '$route.hash': function() {
      if (this.isClick) return
      //路由变化 渲染新的页面
      this.expandedid = []
      const dashboardID = this.$route.query.dashboardID || this.$route.hash.replace('#/', '')
      let cur_menu = ''
      this.leftMenu.forEach(x => {
        if (x.nodeType === 'Folder' && x.children) {
          let menu = x.children.find(c => {
            return c.id == dashboardID
          })
          if (menu) {
            cur_menu = menu
            this.expandedid.push(x.id)
          }
        } else {
          if (x.id == dashboardID) {
            cur_menu = x
            this.expandedid.push(x.id)
          }
        }
      })
      if (cur_menu) {
        this.debounce(cur_menu)
      }
    },
  },
  created() {
    this.analysisList = analysisData
    this.currentid = this.$route.hash.replace('#/', '')
  },
  methods: {
    handleDragStart() {}, //node, ev
    handleDragEnter() {
      //draggingNode, dropNode, ev
      // console.log('tree drag enter: ', dropNode)
    },
    handleDragLeave() {
      //draggingNode, dropNode, ev
      // console.log('tree drag enter: ', dropNode)
    },
    handleDragOver() {
      //draggingNode, dropNode, ev
      // console.log('tree drag enter: ', dropNode)
    },
    handleDragEnd() {
      //draggingNode, dropNode, dropType, ev
      let param = {
        projectID: this.projectID,
      }
      RectifyOrder(param, this.leftMenu).then(() => {
        // console.log('RectifyOrder', v)
      })
    },
    handleDrop() {
      //draggingNode, dropNode, dropType, ev
    },
    //右键
    // nodecontextmenu(event, dropNode, dropType, ev) {
    nodecontextmenu(event, dropNode) {
      this.openeditMenu = true
      this.editMenuTop = event.clientY - 10 + 'px'
      this.clickNode = dropNode
      if (dropNode.nodeType === 'Folder') {
        this.isFile = true
      } else {
        this.isFile = false
      }

      if (dropNode.isOutsideUrl) {
        this.isLinks = true
      } else {
        this.isLinks = false
      }
    },
    changeName() {
      if (this.clickNode.isLocked) {
        let text = this.isFile ? '文件夹' : '页面'
        this.$alert(`该${text}已被锁定, 不允许当前操作~`, '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          customClass: 'theme_message',
        })
        return
      }
      this.isAdd = false
      this.dialogfile = true
    },
    cancel() {
      this.addvisible = false
    },
    next() {
      let path = this.analysisRadio + '/' + this.projectID + '?dashboardID=' + this.clickNode.id
      let url = BI_config.analysisUrl + BI_config.analysisProxy + '/' + path
      window.location.href = url
    },
    delFile(clickNode) {
      if (this.clickNode.isLocked) {
        let text = this.isFile ? '文件夹' : '页面'
        this.$alert(`该${text}已被锁定, 不允许当前操作~`, '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          customClass: 'theme_message',
        })
        return
      }

      this.$confirm('删除该文件夹, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        customClass: 'theme_message',
      })
        .then(() => {
          this.navloading = true
          deleteFolder(this.clickNode.id).then(
            () => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.openeditMenu = false
              const idList = this.leftMenu.map(x => {
                return x.id
              })
              const nodeIndex = idList.indexOf(clickNode.id)
              this.leftMenu.splice(nodeIndex, 1)
              this.navloading = false
              window.location.reload()
            },
            e => {
              this.openeditMenu = false
              this.navloading = false
            }
          )
        })
        .catch(() => {})
    },
    addCard() {
      if (this.clickNode.isLocked) {
        this.$alert(`该页面已被锁定，不允许当前操作~`, '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          customClass: 'theme_message',
        })
        return
      }
      this.addvisible = true
      // this.openCard = true
    },
    choiceCard() {
      this.openCard = true
    },
    delDashboard(clickNode) {
      if (this.clickNode.isLocked) {
        this.$alert(`该页面已被锁定，不允许当前操作~`, '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          customClass: 'theme_message',
        })
        return
      }

      this.$confirm('删除该页面, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        customClass: 'theme_message',
      })
        .then(() => {
          this.navloading = true
          deleteDashboard(this.clickNode.id).then(
            () => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              const dashboardID = this.$route.query.dashboardID || this.$route.hash.split('#/')[1]
              if (Number(dashboardID) === this.clickNode.id) {
                this.$router.push({ path: this.$route.path })
                window.location.reload()
              } else {
                this.leftMenu.forEach((x, xi) => {
                  if (x.nodeType === 'Folder') {
                    x.children.forEach((c, ci) => {
                      if (c.id == clickNode.id) {
                        this.leftMenu[xi].children.splice(ci, 1)
                      }
                    })
                  } else {
                    if (x.id == clickNode.id) {
                      this.leftMenu.splice(xi, 1)
                    }
                  }
                })
                this.navloading = false
              }
            },
            e => {
              this.navloading = false
            }
          )
        })
        .catch(() => {})
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.nodeType === 'Folder' || draggingNode.data.nodeType === 'Page') {
        if (draggingNode.data.nodeType === 'Folder' || dropNode.data.nodeType === 'Page') {
          return type !== 'inner'
        } else {
          return true
        }
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.name.indexOf('三级 3-2-2') === -1
    },
    handleClick(e) {
      if (e.nodeType === 'Folder') {
        // this.$refs.tree.setCheckedNodes([e])
      } else if (e.isOutsideUrl) {
        const url = this.$router.resolve({ name: e.pageHref })
        window.open(url.location.name, '_blank')
      } else {
        this.isClick = true
        setTimeout(() => {
          this.isClick = false
        }, 500)
        this.$store.commit('setdashboardHash', e.id)
        this.currentid = e.id
        this.$router.push({ path: this.$route.path + '#/' + e.id })
      }
    },
    openAdd(command) {
      this.isAdd = true
      this.dialogfile = true
      if (command == 'file') {
        this.addFileType = true
        this.isFile = true
      } else {
        this.addFileType = false
        this.isFile = false
      }
    },
    fileDialogClose() {
      this.dialogfile = false
    },
    changeMenu() {
      this.dialogfile = false
      this.updataMenu()
    },
    lockpage(isFile, clickNode) {
      //锁定页面或者文件夹
      if (isFile == 'file') {
        let id = {
          folderID: this.clickNode.id,
        }
        if (this.clickNode.isLocked) {
          unlockFolder(id).then(() => {
            this.$set(clickNode, 'isLocked', false)
            this.$message({
              type: 'success',
              message: '解锁文件夹成功!',
            })
          })
        } else {
          lockFolder(id).then(() => {
            this.$set(clickNode, 'isLocked', true)
            this.$message({
              type: 'success',
              message: '锁定文件夹成功!',
            })
          })
        }
      } else {
        let id = {
          dashboardID: this.clickNode.id,
        }
        if (this.clickNode.isLocked) {
          unlockDashboard(id).then(() => {
            this.$set(clickNode, 'isLocked', false)
            this.$message({
              type: 'success',
              message: '解锁页面成功!',
            })
          })
        } else {
          lockDashboard(id).then(() => {
            this.$set(clickNode, 'isLocked', true)
            this.$message({
              type: 'success',
              message: '锁定页面成功!',
            })
          })
        }
      }
    },

    // 更新菜单
    updataMenu() {
      getMenuLoad().then(v => {
        this.leftMenu = v.data.dashboards
        this.navloading = false
      })
    },
    tourl(d, url) {
      if (!(url.includes('https://') || url.includes('http://'))) {
      }
      window.open(url)
    },
    init() {
      let param = {
        projectID: this.projectID,
      }
      getBookmarkTab(param).then(v => {
        this.cardData = v.data
        const cards = this.cardData[0] && this.cardData[0].summaries
        this.$store.commit('setAllcards', cards)
      })
    },

    savecard(item) {
      this.openCard = false
      this.addcardkey++
      if (item) {
        this.addvisible = false
        this.$emit('getChangeDash', !this.addDashboard)
      }
    },
  },
  mounted() {
    document.addEventListener('click', e => {
      if (e.target.className != 'editMenu') {
        this.openeditMenu = false
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.leftTree {
  .tree {
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    .tree-box {
      min-height: 100%;
      padding-bottom: 100px;
      position: relative;
    }
    .el-tree {
      padding-top: 10px;
      padding-left: 10px;
      @include theme_bg1($theme-light);
    }
    .addbtn {
      width: 250px;
      position: fixed;
      bottom: 0;
      left: 0;
      @include theme_bg1($theme-light);
      box-shadow: 0 0 8px 0 rgba(136, 152, 170, 0.15);
      padding: 20px;
      text-align: center;
      transition: 0.3s ease-in-out;
      .el-dropdown {
        width: 100px;
        .el-dropdown-link {
          display: block;
          border-radius: 22px;
          @include high_color1($highcolor-cheng);
          @include high_border1($highcolor-cheng);
          width: 100px;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
        }
      }
    }
  }
}
.choiceCardDialog {
  .analysisRadio {
    padding-left: 60px;
    .radio-item {
      display: block;
      margin-bottom: 10px;
      ::v-deep .el-radio {
        .el-radio__label {
          @include theme_color2($theme-dark);
        }
      }
      .radio-tips {
        display: block;
        line-height: 24px;
        color: #aaa;
        padding-left: 25px;
      }
    }
  }
  .choiceBtn {
    display: inline-block;
    margin-left: 85px;
    cursor: pointer;
    @include high_color1($highcolor-cheng);
  }
}
</style>

<style lang="scss">
.leftTree {
  .el-tree {
    .el-tree-node {
      .el-tree-node__content {
        height: 34px;
        line-height: 34px;
        background: none;
        .el-tree-node__expand-icon {
          display: none;
        }
      }
      // &.is-current{
      //   .el-tree-node__content{
      //     .slottree{
      //       &.notfolder{
      //         span{
      //           font-size: 14px;
      //           @include high_color1($highcolor-cheng);
      //           font-weight: bold;

      //         }
      //       }

      //     }
      //   }
      // }
      .currentid {
        span {
          font-size: 14px;
          @include high_color1($highcolor-cheng);
          font-weight: bold;
        }
      }
      &.is-expanded {
        .el-tree-node__content {
          .slottree {
            .el-icon-caret-right:before {
              content: '\e790';
            }
          }
        }
      }
    }
    .el-tree-node__children {
      .el-tree-node {
        .el-tree-node__content {
          height: 34px;
          line-height: 34px;
          &:hover,
          &:focus {
            @include high_color1($highcolor-cheng);
            .el-tree-node__label {
              @include high_color1($highcolor-cheng);
            }
          }
          .el-tree-node__label {
            @include theme_color2($theme-light);
            font-weight: normal;
          }
        }
        &.is-current,
        &.is-checked {
          .el-tree-node__content {
            .el-tree-node__label {
              @include high_color1($highcolor-cheng);
            }
          }
        }
      }
    }
    .slottree {
      width: 100%;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: calc(100% - 40px);
        font-size: 14px;
        @include theme_color2($theme-light);
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      i {
        display: inline-block;
        width: 24px;
        padding: 6px;
        font-size: 14px;
        color: #d1d1d1;
      }
      &.notfolder {
        span {
          font-weight: normal;
          max-width: 190px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.editMenu {
  position: fixed;
  left: 120px;
  width: 120px;
  padding: 10px;
  z-index: 9;
  border-radius: 6px;
  @include theme_bg1($theme-light);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px 0 rgba(136, 152, 170, 0.15);
  ul {
    li {
      font-size: 14px;
      @include theme_color2($theme-light);
      cursor: pointer;
      line-height: 24px;
      display: flex;
      align-items: center;
      i {
        color: #adb5bd;
        font-size: 12px;
        margin-right: 5px;
      }
      &:hover {
        @include high_color1($highcolor-cheng);
      }
    }
  }
}
.dialogfile {
  .el-dialog {
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-form-item__label {
            line-height: 40px;
          }
          .el-form-item__content {
            line-height: 40px;
          }
          .el-textarea {
            min-height: 60px;
            font-size: 12px;
            textarea {
              min-height: 60px !important;
              background: none;
              border: none;
              resize: none;
              @include theme_color2($theme-dark);
            }
          }
        }
      }
    }
  }
}
</style>
