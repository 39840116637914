<template>
  <div class="addcards">
    <el-dialog
      class="openCard thmemBottomMenu"
      title="添加卡片"
      :visible.sync="visibleCard"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="cancel"
      v-loading="addcardloading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    >
      <div class="dialog-body">
        <div class="search-card"><el-input type="text" v-model="cardKeyword" placeholder="请输入关键字" @input.native="filterWord"></el-input></div>
        <div class="cardlist dis_flex_center">
          <div class="card-left">
            <!-- <el-tabs v-if="cardData.length > 1" v-model="activetab" @tab-click="tabClick"> -->
              <!-- <el-tab-pane label="BI 2.0" name="BI 2.0"> </el-tab-pane> -->
              <!-- <el-tab-pane label="BI 1.0" name="BI 1.0"> </el-tab-pane> -->
            <!-- </el-tabs> -->
            <el-tree
              class="cardtree"
              ref="card"
              :data="showleftCardData"
              node-key="id"
              :props="defaultProps2"
              :default-expanded-keys="cardsexpand"
              @node-click="cardClick"
              :key="leftcardkey"
            >
              <div class="custom-tree-slot" slot-scope="{ node, data }">
                <span
                  >{{ node.label }}<b v-if="data.id">({{ data.id }})</b></span
                >
              </div>
            </el-tree>
          </div>
          <i class="el-icon-sort"></i>
          <div class="card-right">
            <ul class="checkedList">
              <li v-for="(item, index) in rightCardList" :key="index">
                {{ item.name }}<b>({{ item.id }})</b><i v-if="item.canVisit" class="iconfont iconshanchu" @click="delCard(index)"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="cancel" size="small" @click="cancel">取 消</el-button>
        <el-button type="primary" size="small" @click="debounce()">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { addBookMarks, getBookmarks } from '@/api/apiV2_dashboard'
export default {
  props: ['openCard', 'cardData', 'clickNode'],
  data() {
    return {
      visibleCard: false,
      defaultProps2: {
        children: 'bookmarks',
        label: 'name',
      },
      cardsexpand: [],
      activetab: 'BI 2.0',

      cardKeyword: '',
      addcardloading: false,
      showleftCardData: [],
      leftCardData: [],
      rightCardList: [],
      leftcardkey: 0,
      debounce: this.$commonJs2.debounce(this.saveCard, 1000, true),
    }
  },
  watch: {
    openCard: function() {
      this.visibleCard = this.openCard
    },
  },
  computed: {
    ...mapState({
      leftNav: state => state.leftNav,
      projectID: state => state.projectID,
      system: state => state.system,
    }),
  },
  created() {
    this.visibleCard = this.openCard
    if (this.cardData && this.cardData[0]) {
      this.leftCardData = JSON.parse(JSON.stringify(this.cardData[0].summaries))
      this.showleftCardData = JSON.parse(JSON.stringify(this.cardData[0].summaries)).filter(x=>x.name!='detaildata')
    }
    this.init()
  },
  methods: {
    init() {
      this.addcardloading = true
      getBookmarks(this.system, this.clickNode.id).then(v => {
        this.rightCardList = v.data
        this.scenekey++
        this.isaddcard()
        this.addcardloading = false
      })
    },
    tabClick(item, tab) {
      this.leftCardData = JSON.parse(JSON.stringify(this.cardData[item.index].summaries))
      this.showleftCardData = JSON.parse(JSON.stringify(this.cardData[item.index].summaries))
      this.filterWord()
    },
    cardClick(data) {
      if (data.id && !data.disabled) {
        data.canVisit = true
        this.rightCardList.push(data)
        this.rightCardList.forEach((x, i) => {
          this.$set(this.rightCardList[i], i, data)
        })
        this.isaddcard()
      }
    },
    // 判断是否添加
    isaddcard() {
      // this.$forceUpdate()
      let checkedIDlist = this.rightCardList.map(x => {
        return x.id
      })
      this.showleftCardData.forEach(list => {
        list.bookmarks.forEach(k => {
          if (checkedIDlist.indexOf(k.id) > -1) {
            this.$set(k, 'disabled', true)
          } else {
            this.$set(k, 'disabled', false)
          }
          // this.$set(this.showleftCardData[idx1].children,idx2,k)
        })
      })
    },
    filterWord() {
      setTimeout(() => {
        //关键字不为空 则拷贝数据查询 否则等于全部数据
        let leftCardData = JSON.parse(JSON.stringify(this.leftCardData))
        if (this.cardKeyword) {
          this.cardsexpand = this.leftCardData.map(x => {
            return x.id
          })
        } else {
          this.cardsexpand = []
        }
        this.showleftCardData = leftCardData.filter(el => {
          el.bookmarks = el.bookmarks.filter(e => {
            // return e.name && this.$commonJs2.matchSoftly(e.name, this.cardKeyword)
            return (e.name && e.name.includes(this.cardKeyword)) || (e.id && String(e.id).indexOf(this.cardKeyword) > -1)
          })
          return el.bookmarks.length > 0
        })
        this.isaddcard()
      }, 100)
    },
    delCard(idx) {
      this.rightCardList.splice(idx, 1)
      this.isaddcard()
    },
    cancel() {
      this.rightCardList = []
      this.$emit('savecard')
    },
    saveCard() {
      let books = this.rightCardList.map(x => {
        return x.id
      })
      let p = {
        DashboardID: this.clickNode.id,
        BookmarkIDs: books,
      }
      addBookMarks(p).then(() => {
        // 是否在当前仪表盘添加
          this.visibleCard = false
        if (this.clickNode.id == this.$route.hash.replace('#/', '')) {
          this.$emit('savecard', 'save')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.openCard {
  .search-card {
    width: 300px;
  }
  .dialog-body {
    padding: 20px;
  }
  .dialog-footer {
    padding: 15px;
  }
  .cardlist {
    margin-top: 20px;
    .card-left {
      width: 350px;
      height: 316px;
      background: none;
      overflow: auto;
      @include theme_border_color($theme-border-light);
      border-radius: 4px;
    }
    .el-icon-sort {
      font-size: 20px;
      color: #adb5bd;
      transform: rotate(90deg);
      margin: 0 15px;
    }
    .card-right {
      width: 350px;
      height: 316px;
      border-radius: 4px;
      @include theme_border_color($theme-border-light);
      padding:  10px;
      ul {
        width: 320px;
        height: 276px;
        overflow: auto;
        li {
          line-height: 26px;
          position: relative;
          padding: 0 20px 0 10px;
          b {
            font-weight: normal;
            color: #999;
            margin-left: 4px;
          }
          i {
            display: none;
            color: #999;
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
          }
          &:hover {
            @include high_color1($highcolor-cheng);
            i {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.cardlist {
  .card-left {
    .el-tabs {
      .el-tabs__nav {
        width: 100%;
        .el-tabs__active-bar {
          @include high_bg1($highcolor-lan);
        }
        .el-tabs__item {
          width: 50%;
          text-align: center;
          @include theme_color2($theme-light);
          &.is-active,
          &:hover {
            @include high_color1($highcolor-cheng);
          }
        }
      }
    }
  }
}
.cardtree {
  padding-top: 10px;
  background: none !important;
  @include theme_color2($theme-dark);
  > .el-tree-node {
    &:focus > .el-tree-node__content {
      background: none;
    }
    > .el-tree-node__content {
      background: none;
      &:hover {
        // @include high_color1($highcolor-cheng);
        background: none;
      }
    }

    [aria-disabled='true'].el-tree-node {
      > .el-tree-node__content {
        @include high_color1($highcolor-cheng);
      }
    }
    .el-tree-node {
      > .el-tree-node__content {
        background: none;
        &:hover {
          @include high_color1($highcolor-cheng);
          background: none;
        }
      }

      .custom-tree-slot {
        span {
          b {
            font-weight: normal;
            color: #999;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
