<template>
  <div
    class="layout"
    id="layoutid"
    :class="{ closeLeft: closeLeft }"
    v-cloak
    v-loading.fullscreen.lock="fullLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <headerComponent v-if="headInfo" class="layout-head" :headdata="headInfo" @getInfo="getLeftStatus"></headerComponent>
    <div class="page-main">
      <leftNav class="page-l" :dashboard="leftInfo" :addDashboard="isAddDashboard" @getChangeDash="getChange"></leftNav>
      <div class="page-r" :class="{ hasnews: news && news.isShow && news.detailUrl }">
        <news v-if="basicInfo && projectID" system="bi"></news>
        <router-view :sdata="closeLeft" class="content" :addDashboard="isAddDashboard" :checkmobile="checkmobile"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import headerComponent from '@/components/headerComponent'
import news from '@/components/news.vue'
import leftNav from '@/components/leftNav/index.vue'
import BI_config from '@/utils/config'

export default {
  name: 'layout',
  data() {
    return {
      theme: '',
      highColor: '',
      closeLeft: false, //菜单开关
      changeDashboard: '', //添加卡片的仪表盘
      leftInfo: null,
      headInfo: null,
      fullLoading: false,
      isAddDashboard: false,
      checkmobile: false,
    }
  },

  components: {
    headerComponent,
    leftNav,
    news,
  },
  watch: {
    '$route.fullPath': function() {
      if(!this.$route.fullPath.includes('/mobile/')){
        this.checkmobile = true
      }
    },
  },
  computed: {
    ...mapState({
      menus: state => state.menus,
      projectID: state => state.projectID,
      system: state => state.system,
      basicInfo: state => state.basicInfo,
      news: state => state.news,
    }),
  },
  created() {
    if (this.projectID) {
      this.getLoad()
    } else {
      this.$router.push({ path: `${BI_config.dashboardProxy}/list` })
    }
  },
  methods: {
    async getLoad() {
      setTimeout(() => {
        // 获取菜单
        this.leftInfo = this.menus.dashboards
        let defaultMenus = this.menus.defaultMenus

        //数据面板跳转链接
        let custom = this.menus.customMenus[0]
        //分析模型跳转链接
        let analysis = defaultMenus.find(x => {
          return x.code == 'analysis'
        })
        if (analysis && analysis.children.length > 0) {
        }
        //系统管理跳转链接
        let system = defaultMenus.find(x => {
          return x.code == 'system'
        })
        if (system && system.children.length > 0) {
        }
        let info = {
          custom: null,
          menus: null,
        }

        info.custom = custom
        info.menus = defaultMenus
        this.headInfo = info

        this.$store.commit('setDashboards', this.menus.dashboards)
      }, 10)
    },
    getLeftStatus(e) {
      this.closeLeft = e.closeLeft
      this.headInfo = e
    },
    getChange(e) {
      this.isAddDashboard = e
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
.layout {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  min-height: 100%;
  .layout-head {
    width: 100%;
    min-width: 1000px;
    height: 60px;
    @include theme_bg1($theme-light);
    position: fixed;
    z-index: 99;
    transition: 0.3s ease-in-out;
  }
  .page-l {
    width: 250px;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    z-index: 99;
    @include theme_bg1($theme-light);
    transition: 0.3s ease-in-out;
  }
  .page-main {
    height: 100%;
    margin-left: 250px;
    transition: 0.3s ease-in-out;
    .page-r {
      padding: 0;
      padding-top: 80px;
      transition: 0.3s ease-in-out;
      height: 100%;
      &.hasnews {
        padding-top: 130px;
      }

      .content {
        transition: 0.3s ease-in-out;
        height: 100%;
        bottom: 0px;
        width: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track-piece {
          border-radius: 10px;
          background: transparent;
        }
      }
    }
  }
  &.closeLeft {
    .headerBox {
      .logo {
        margin-left: -250px;
        transition: 0.3s ease-in-out;
      }
      .logo-r {
        width: 100%;
        transition: 0.3s ease-in-out;
      }
    }
    .page-l {
      left: -250px;
      transition: 0.3s ease-in-out;
      .tree-box {
        .addbtn {
          left: -250px;
          transition: 0.3s ease-in-out;
        }
      }
    }
    .page-main {
      margin-left: 0;
      transition: 0.3s ease-in-out;
    }
    .page-r {
      width: 100%;
      transition: 0.3s ease-in-out;
      .news {
        width: calc(100% - 37px);
        transition: 0.3s ease-in-out;
      }
    }
  }
}
</style>
