<template>
  <el-dialog
    class="dialogfile thmemBottomMenu"
    :title="isAdd ? (isFileType ? '添加文件夹' : '添加页面') : isFileType ? '编辑文件夹' : '编辑页面'"
    :visible.sync="visible"
    width="600px"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="fileClose"
  >
    <div class="dialog-body">
      <el-form>
        <el-form-item :label="isFileType ? '文件夹名称' : '中文名称：'" :label-width="formLabelWidth">
          <el-input v-model="filename" autocomplete="off" :placeholder="isFileType ? '请输入文件夹名称' : '请输入中文名称'"></el-input>
        </el-form-item>
        <el-form-item v-if="!isFileType" label="英文名称：" :label-width="formLabelWidth">
          <el-input v-model="filenameEN" autocomplete="off" :placeholder="'请输入英文名称'"></el-input>
        </el-form-item>
        <el-form-item v-if="isAdd && !isFileType" label="页面路径：" :label-width="formLabelWidth">
          <el-radio v-model="dataRadio" label="0">数据面版</el-radio>
          <el-radio v-model="dataRadio" label="1">URL链接</el-radio>
          <div class="urllink" v-if="dataRadio == '1'">
            <el-input type="textarea" autosize placeholder="https://" v-model="pageurl"> </el-input>
          </div>
          <span class="errurltips" v-if="dataRadio == '1' && errorurl">URL链接地址不完整</span>
        </el-form-item>
        <el-form-item v-if="!isAdd && isLinks" label="URL链接：" :label-width="formLabelWidth">
          <el-input v-model="pageurl" autocomplete="off" placeholder="https://"></el-input>
          <span class="errurltips" v-if="isLinks && errorurl">URL链接地址不完整</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog-footer">
      <el-button class="cancel" size="small" @click="visible = false">取 消</el-button>
      <el-button type="primary" size="small" @click="save(isFileType)">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { updataDashboard, addFolder, addDashboard, updateFolder } from '@/api/apiV2_dashboard'
export default {
  props: ['isAdd', 'isFile', 'dialogfile', 'clickNode'],
  data() {
    return {
      visible: false,
      formLabelWidth: '120px',
      isFileType: false,
      pageurl: '',
      filename: '',
      filenameEN: '',
      dataRadio: '0',
      errorurl: false,
      isLinks: false,
    }
  },
  computed: {
    ...mapState({
      leftNav: state => state.leftNav,
      projectID: state => state.projectID,
      system: state => state.system,
    }),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.visible = this.dialogfile
      this.isFileType = this.isFile
      if (this.isAdd) {
      } else {
        this.filename = this.clickNode.name
        this.filenameEN = this.clickNode.nameEN
        this.pageurl = this.clickNode.pageHref
        this.isLinks = this.clickNode.isOutsideUrl
      }
    },
    fileClose() {
      this.pageurl = ''
      this.filename = ''
      this.filenameEN = ''
      this.dataRadio = '0'
      this.$emit('fileDialogClose')
    },
    save(isFile) {
      if (this.isAdd) {
        this.saveFile(isFile)
      } else {
        this.saveDashboard(isFile)
      }
    },
    saveFile(isFile) {
      const _this = this
      if (this.dataRadio == 1 && this.testurl()) {
        return
      }
      if (isFile) {
        if (!this.filename || this.filename == ' ') {
          this.$message.error('文件夹名字不能为空')
          return
        }
        let name = {
          name: this.filename,
        }
        this.navloading = true
        addFolder(name)
          .then(() => {
            _this.$emit('changeMenu')
            _this.filename = ''
          })
          .catch(() => {
            _this.navloading = false
          })
      } else {
        let name = {
          name: this.filename,
          nameEN: this.filenameEN,
          pageType: this.dataRadio,
          pageHref: this.pageurl,
        }
        if (this.filename) {
          this.navloading = true
          addDashboard(name).then(() => {
            _this.$emit('changeMenu')
            _this.filename = ''
            _this.pageurl = ''
          })
        } else {
          this.$message.error('数据面版名字不能为空')
        }
      }
    },
    saveDashboard(isFile) {
      const _this = this
      if (this.isLinks && this.testurl()) {
        return
      }
      this.navloading = true
      if (!this.filename || this.filename == ' ') {
        this.$message.error('文件夹名字不能为空')
        return
      }
      if (isFile) {
        let data = {
          id: this.clickNode.id,
          name: this.filename,
        }
        updateFolder(data).then(() => {
          _this.$emit('changeMenu')
        })
      } else {
        let data = {
          DashboardID: this.clickNode.id,
          Name: this.filename,
          nameEN: this.filenameEN,
          pageHref: this.pageurl,
        }
        updataDashboard(data).then(() => {
          _this.$emit('changeMenu')
        })
      }
    },
    testurl() {
      this.errorurl = !this.pageurl.includes('https://') && !this.pageurl.includes('http://')
      return this.errorurl
    },
  },
}
</script>

<style lang="scss">
.dialogfile {
  .el-dialog {
    .el-dialog__body {
      .el-form {
        margin-bottom: 30px;
        .el-form-item {
          margin-bottom: 10px;
          .el-radio {
            @include theme_color2($theme-dark);
          }
          .urllink {
            margin-top: 10px;
            @include theme_border_color($theme-border-light);
            min-height: 60px;
            max-height: 100px;
            overflow: auto;
          }
          .errurltips {
            display: block;
            color: red;
            line-height: 24px;
            font-size: 12px;
          }
        }
        .el-input {
          width: 400px;
        }
      }
    }
  }
}
</style>

<style lang="scss"></style>
